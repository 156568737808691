import {Options, Vue} from "vue-class-component";
import {Prop} from "vue-property-decorator";
import TabMenu from "primevue/tabmenu";
import {Activity, ActivityStatusEnum, ActivityWorkTypeEnum} from "@/model/api/Activity";
import {
    ActivityDataAutocomplete,
    CompanyAutocomplete,
    ContractAutocomplete, MaterialAutocomplete, MaterialSelect,
    TimeZoneDropdown, UserAutocomplete, UserFlattenAutocomplete,
    ZoneAutocomplete
} from "@components";
import {ProjectsRoutesEnum} from "@/modules/projects/router";
import {PaymentTypeEnum} from "@/model/enums/PaymentTypeEnum";
import {Company} from "@/model/api/Company";
import {Contract} from "@/model/api/Contract";
import {UserRoleEnum} from "@/model/enums/UserRoleEnum";
import {FilterMatchMode} from "primevue/api";
import {User} from "@/model/api/User";
import {Material} from "@/model/api/Material";

@Options({
    inheritAttrs: false,
    emits: ['save'],
    components: {
        TabMenu,
        ActivityDataAutocomplete,
        CompanyAutocomplete,
        ZoneAutocomplete,
        TimeZoneDropdown,
        ContractAutocomplete,
        MaterialSelect,
        MaterialAutocomplete,
        UserAutocomplete,
        UserFlattenAutocomplete
    }
})
export default class ActivityData extends Vue {
    @Prop() activity!: Activity;

    @Prop() readonly isLoading!: boolean;

    public project_customers = [];

    private fieldsToAutocomplete = ['poc_name', 'poc_phone', 'poc_email', 'cost_code', 'customer'];

    get paymentCYCLE() {
        return PaymentTypeEnum.CYCLE;
    }

    get usersQueryOpts(){
        return {
            sortField: 'name',
            sortOrder: 1,
            per_page: 100,
            page: 1
        }
    }

    search(event) {
        setTimeout(() => {
            if (!event.query.trim().length) {
                this.project_customers = [...this.activity.project.project_customers];
            } else {
                this.project_customers = this.activity.project.project_customers.filter((customer) => {
                    return customer.business_name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
        }, 250);
    }

    get paymentHOUR() {
        return PaymentTypeEnum.HOUR;
    }

    get paymentTON() {
        return PaymentTypeEnum.TON;
    }

    get statusOptions() {
        return [
            {label: this.$t(`activity.status.${ActivityStatusEnum.OPEN}`), value: ActivityStatusEnum.OPEN},
            {label: this.$t(`activity.status.${ActivityStatusEnum.CLOSED}`), value: ActivityStatusEnum.CLOSED},
        ];
    }

    get userFilters(){
        return {
            "role":{
                "value": UserRoleEnum.FOREMAN,
                "matchMode": FilterMatchMode.EQUALS
            },
            "customer.id": {
                "value": this.activity.customer?.id,
                "matchMode": FilterMatchMode.EQUALS
            }
        }
    }

    get workTypeOptions() {
        return [
            {label: this.$t(`activity.work_type.${ActivityWorkTypeEnum.IMPORT}`), value: ActivityWorkTypeEnum.IMPORT},
            {label: this.$t(`activity.work_type.${ActivityWorkTypeEnum.EXPORT}`), value: ActivityWorkTypeEnum.EXPORT},
            {label: this.$t(`activity.work_type.${ActivityWorkTypeEnum.ONSITE}`), value: ActivityWorkTypeEnum.ONSITE},
        ];
    }

    get projectRoute() {
        return ProjectsRoutesEnum.PROJECT_DETAIL;
    }

    onSelectedJob($event) {
        this.fieldsToAutocomplete.forEach(field => {
            this.activity[field] = $event[field];
        });
    }

    onForemanSelected(user: User) {
        if (user) {
            this.activity.foreman_id = user.id;
        } else {
            this.activity.foreman = null;
            this.activity.foreman_id = null;
        }
    }

    onMaterialSelect(material: Material) {
        if (material) {
            this.activity.material_id = material.id;
        } else {
            this.activity.material = null;
            this.activity.material_id = null;
        }
    }

    onContractSelect(contract: Contract) {
        if (contract) {
            this.activity.contract_id = contract.id;
        } else {
            this.activity.contract = null;
            this.activity.contract_id = null;
        }
    }

    onZoneSelect() {
        this.activity.zone_id = this.activity.zone.id;
    }

    onCustomerSelected({value}) {

        const company: Company = value

        this.activity.contract_id = null
        this.activity.contract = null

        this.activity.foreman = null
        this.activity.foreman_id = null

        if (company?.id != this.activity.customer_id) {
            this.activity.zone = null
            this.activity.zone_id = null
        }

        if (company) {
            this.activity.customer = company
            this.activity.customer_id = company.id
        } else {
            this.activity.customer = null
            this.activity.customer_id = null

        }
    }

}
