import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withKeys as _withKeys, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-5c0df79d")
const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["onClick"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_DateFormatted = _resolveComponent("DateFormatted")!
  const _component_AppTable = _resolveComponent("AppTable")!

  return (_openBlock(), _createBlock(_component_AppTable, {
    ref: "dataTable",
    "striped-rows": false,
    filtersSchema: _ctx.filters,
    service: _ctx.service,
    sortField: `start_datetime`,
    sortOrder: 1,
    rowClass: _ctx.getRowClass,
    showActions: false,
    onRowClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToDetail($event.data))),
    onEdit: _ctx.goToDetail
  }, {
    columns: _withCtx(({onApplyFilter}) => [
      _createVNode(_component_Column, {
        header: "Status",
        field: "status",
        showFilterMatchModes: false,
        showFilterOperator: false
      }, {
        body: _withCtx(({data}) => [
          _createTextVNode(_toDisplayString(_ctx.$t(`shift.status_${data.status}`)), 1)
        ]),
        filter: _withCtx(({filterModel}) => [
          _createVNode(_component_Dropdown, {
            modelValue: filterModel.value,
            "onUpdate:modelValue": ($event: any) => (filterModel.value = $event),
            options: _ctx.statusOptions,
            placeholder: "Select status",
            "option-value": "value",
            "option-label": "label"
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
        ]),
        filterapply: _withCtx(({filterCallback}) => [
          _createVNode(_component_Button, {
            label: "apply",
            onClick: ($event: any) => (onApplyFilter(filterCallback))
          }, null, 8, ["onClick"])
        ]),
        _: 2
      }, 1024),
      _createVNode(_component_Column, {
        field: "id",
        showFilterMatchModes: false,
        header: "Shift ID"
      }, {
        filter: _withCtx(({filterModel, filterCallback}) => [
          _createVNode(_component_InputText, {
            modelValue: filterModel.value,
            "onUpdate:modelValue": ($event: any) => (filterModel.value = $event),
            placeholder: "Search by shift ID",
            onKeyup: _withKeys(($event: any) => (onApplyFilter(filterCallback)), ["enter"])
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeyup"])
        ]),
        filterclear: _withCtx(({filterCallback}) => [
          _createVNode(_component_Button, {
            type: "button",
            label: _ctx.$t('common.clear'),
            class: "p-button-outlined",
            onClick: ($event: any) => (onApplyFilter(filterCallback))
          }, null, 8, ["label", "onClick"])
        ]),
        filterapply: _withCtx(({filterCallback}) => [
          _createVNode(_component_Button, {
            type: "button",
            label: _ctx.$t('common.apply'),
            class: "p-button-success",
            onClick: ($event: any) => (onApplyFilter(filterCallback))
          }, null, 8, ["label", "onClick"])
        ]),
        _: 2
      }, 1024),
      _createVNode(_component_Column, {
        field: "code",
        header: "Shift Name",
        showFilterMatchModes: false
      }, {
        filter: _withCtx(({filterModel, filterCallback}) => [
          _createVNode(_component_InputText, {
            modelValue: filterModel.value,
            "onUpdate:modelValue": ($event: any) => (filterModel.value = $event),
            placeholder: "Search by shift name",
            onKeyup: _withKeys(($event: any) => (onApplyFilter(filterCallback)), ["enter"])
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeyup"])
        ]),
        filterclear: _withCtx(({filterCallback}) => [
          _createVNode(_component_Button, {
            type: "button",
            label: _ctx.$t('common.clear'),
            class: "p-button-outlined",
            onClick: ($event: any) => (onApplyFilter(filterCallback))
          }, null, 8, ["label", "onClick"])
        ]),
        filterapply: _withCtx(({filterCallback}) => [
          _createVNode(_component_Button, {
            type: "button",
            label: _ctx.$t('common.apply'),
            class: "p-button-success",
            onClick: ($event: any) => (onApplyFilter(filterCallback))
          }, null, 8, ["label", "onClick"])
        ]),
        _: 2
      }, 1024),
      _createVNode(_component_Column, {
        field: "start_datetime",
        header: "Start Date",
        sortable: true,
        style: {"min-width":"12rem"}
      }, {
        body: _withCtx(({data}) => [
          _createVNode(_component_DateFormatted, {
            date: data.start_datetime,
            format: "YYYY-MM-DD hh:mm A"
          }, null, 8, ["date"])
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "end_datetime",
        header: "End Date",
        sortable: true,
        style: {"min-width":"12rem"}
      }, {
        body: _withCtx(({data}) => [
          _createVNode(_component_DateFormatted, {
            date: data.end_datetime,
            format: "YYYY-MM-DD hh:mm A"
          }, null, 8, ["date"])
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "w3load",
        header: "Load Site"
      }, {
        body: _withCtx(({data}) => [
          _createElementVNode("div", null, [
            _createElementVNode("div", null, [
              _createElementVNode("strong", null, _toDisplayString(data.zone_load.name), 1)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("i", null, _toDisplayString(data.w3load), 1)
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "w3dump",
        header: "Dump Site"
      }, {
        body: _withCtx(({data}) => [
          _createElementVNode("div", null, [
            _createElementVNode("div", null, [
              _createElementVNode("strong", null, _toDisplayString(data.zone_dump.name), 1)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("i", null, _toDisplayString(data.w3dump), 1)
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "activity.name",
        header: "Activity",
        showFilterMatchModes: false
      }, {
        body: _withCtx(({data}) => [
          _createElementVNode("a", {
            class: _normalizeClass({'pointer' : _ctx.imAdmin}),
            onClick: ($event: any) => (_ctx.goToActivityDetails(data))
          }, _toDisplayString(data.activity_id) + " " + _toDisplayString(data.activity?.name), 11, _hoisted_1)
        ]),
        filter: _withCtx(({filterModel, filterCallback}) => [
          _createVNode(_component_InputText, {
            modelValue: filterModel.value,
            "onUpdate:modelValue": ($event: any) => (filterModel.value = $event),
            placeholder: "Search by Activity name",
            onKeyup: _withKeys(($event: any) => (onApplyFilter(filterCallback)), ["enter"])
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeyup"])
        ]),
        filterclear: _withCtx(({filterCallback}) => [
          _createVNode(_component_Button, {
            type: "button",
            label: _ctx.$t('common.clear'),
            class: "p-button-outlined",
            onClick: ($event: any) => (onApplyFilter(filterCallback))
          }, null, 8, ["label", "onClick"])
        ]),
        filterapply: _withCtx(({filterCallback}) => [
          _createVNode(_component_Button, {
            type: "button",
            label: _ctx.$t('common.apply'),
            class: "p-button-success",
            onClick: ($event: any) => (onApplyFilter(filterCallback))
          }, null, 8, ["label", "onClick"])
        ]),
        _: 2
      }, 1024),
      _createVNode(_component_Column, {
        field: "project.name",
        header: "Project",
        showFilterMatchModes: false
      }, {
        body: _withCtx(({data}) => [
          _createElementVNode("a", {
            class: _normalizeClass({'pointer' : _ctx.imAdmin}),
            onClick: ($event: any) => (_ctx.goToProjectDetails(data))
          }, _toDisplayString(data.project_id) + " " + _toDisplayString(data.project?.name), 11, _hoisted_2)
        ]),
        filter: _withCtx(({filterModel, filterCallback}) => [
          _createVNode(_component_InputText, {
            modelValue: filterModel.value,
            "onUpdate:modelValue": ($event: any) => (filterModel.value = $event),
            placeholder: "Search by Project name",
            onKeyup: _withKeys(($event: any) => (onApplyFilter(filterCallback)), ["enter"])
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeyup"])
        ]),
        filterclear: _withCtx(({filterCallback}) => [
          _createVNode(_component_Button, {
            type: "button",
            label: _ctx.$t('common.clear'),
            class: "p-button-outlined",
            onClick: ($event: any) => (onApplyFilter(filterCallback))
          }, null, 8, ["label", "onClick"])
        ]),
        filterapply: _withCtx(({filterCallback}) => [
          _createVNode(_component_Button, {
            type: "button",
            label: _ctx.$t('common.apply'),
            class: "p-button-success",
            onClick: ($event: any) => (onApplyFilter(filterCallback))
          }, null, 8, ["label", "onClick"])
        ]),
        _: 2
      }, 1024),
      _createVNode(_component_Column, {
        field: "job_site",
        header: "Job Site",
        showFilterMatchModes: false
      }, {
        filter: _withCtx(({filterModel, filterCallback}) => [
          _createVNode(_component_InputText, {
            modelValue: filterModel.value,
            "onUpdate:modelValue": ($event: any) => (filterModel.value = $event),
            placeholder: "Search by job site",
            onKeyup: _withKeys(($event: any) => (onApplyFilter(filterCallback)), ["enter"])
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeyup"])
        ]),
        filterclear: _withCtx(({filterCallback}) => [
          _createVNode(_component_Button, {
            type: "button",
            label: _ctx.$t('common.clear'),
            class: "p-button-outlined",
            onClick: ($event: any) => (onApplyFilter(filterCallback))
          }, null, 8, ["label", "onClick"])
        ]),
        filterapply: _withCtx(({filterCallback}) => [
          _createVNode(_component_Button, {
            type: "button",
            label: _ctx.$t('common.apply'),
            class: "p-button-success",
            onClick: ($event: any) => (onApplyFilter(filterCallback))
          }, null, 8, ["label", "onClick"])
        ]),
        _: 2
      }, 1024),
      _createVNode(_component_Column, {
        header: "minimum #",
        field: "trucks_min"
      }),
      _createVNode(_component_Column, {
        header: "Maximum #",
        field: "trucks_required"
      }),
      _createVNode(_component_Column, {
        header: "# of accepted",
        field: "trucks_engaged"
      }),
      _createVNode(_component_Column, {
        field: "customer.business_name",
        header: "Customer",
        showFilterMatchModes: false
      }, {
        filter: _withCtx(({filterModel, filterCallback}) => [
          _createVNode(_component_InputText, {
            modelValue: filterModel.value,
            "onUpdate:modelValue": ($event: any) => (filterModel.value = $event),
            placeholder: "Customer",
            onKeyup: _withKeys(($event: any) => (onApplyFilter(filterCallback)), ["enter"])
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeyup"])
        ]),
        filterclear: _withCtx(({filterCallback}) => [
          _createVNode(_component_Button, {
            type: "button",
            label: _ctx.$t('common.clear'),
            class: "p-button-outlined",
            onClick: ($event: any) => (onApplyFilter(filterCallback))
          }, null, 8, ["label", "onClick"])
        ]),
        filterapply: _withCtx(({filterCallback}) => [
          _createVNode(_component_Button, {
            type: "button",
            label: _ctx.$t('common.apply'),
            class: "p-button-success",
            onClick: ($event: any) => (onApplyFilter(filterCallback))
          }, null, 8, ["label", "onClick"])
        ]),
        _: 2
      }, 1024)
    ]),
    _: 1
  }, 8, ["filtersSchema", "service", "rowClass", "onEdit"]))
}